import React, { useEffect, useState } from "react"
import { Slider, useMediaQuery, useTheme } from "@material-ui/core"
import "./loanCalculator.scss"
import { useTranslation } from "react-i18next"
import asetkuLogo from "../../assets/images/logos/asetku-logo.png"
import moneyPouch from "../../assets/images/borrower/money-pouch.png"
import moneyPaid from "../../assets/images/borrower/money-paid.png"
import checkDevice from "../../helpers/checkDevice"

function LoanCalculator() {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"))
  const loanOption = [30, 45, 60, 75, 90]
  const [selectedLoan, setSelectedLoan] = useState(loanOption[0])
  const sliderMinVal = 500000
  const sliderMaxVal = 4000000
  const sliderdefaultVal = 1000000
  const sliderStepVal = 50000
  const [sliderValue, setSliderValue] = useState(sliderdefaultVal)
  const interest = 0.003
  const appStore = "https://apps.apple.com/id/app/asetku/id1300452602"
  const playStore =
    "https://play.google.com/store/apps/details?id=com.silvrr.finance"
  const otherDownload = "/lender/#download"

  const rupiah = number => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number)
  }

  const handleSliderChange = (val, newVal) => {
    setSliderValue(newVal)
  }

  const calculatePaidAmount = (nominal, days) => {
    const tempNominalXinterest = nominal * interest
    return nominal + tempNominalXinterest * days
  }

  useEffect(() => {
    setTimeout(() => {
      setSliderValue(sliderMaxVal)
    }, 2400)
  }, [])

  return (
    <>
      {isDesktop ? (
        <div className="loan-calculator">
          <div className="total-loan">
            <p>{t("home.banner.total-loan")}</p>
            <h4>{rupiah(sliderValue)}</h4>

            <Slider
              min={sliderMinVal}
              max={sliderMaxVal}
              defaultValue={sliderdefaultVal}
              step={sliderStepVal}
              value={sliderValue}
              onChange={handleSliderChange}
              className="slider"
            />
          </div>
          <div className="loan-options">
            {loanOption.map((e, i) => (
              <div
                className={selectedLoan === e ? "option active" : "option"}
                key={i}
                onClick={() => {
                  setSelectedLoan(e)
                }}
              >
                {e.toString() + " " + t("home.banner.days")}
              </div>
            ))}
          </div>
          <div className="total-paid">
            <p>{t("home.banner.total-to-be-paid")}</p>
            <h4>{rupiah(calculatePaidAmount(sliderValue, selectedLoan))}</h4>
          </div>

          <div className="download-link">
            <a
              href={
                checkDevice() === "Android"
                  ? playStore
                  : checkDevice() === "iOS"
                  ? appStore
                  : otherDownload
              }
              target={checkDevice() === "Other" ? "_self" : "_blank"}
            >
              {t("home.banner.apply-now")}
            </a>
          </div>
        </div>
      ) : (
        <div className="loan-calculator-mobile">
          <div className="total-loan">
            <div className="header">
              <img src={asetkuLogo} alt="" />
              <p>{t("home.banner.total-loan")}</p>
            </div>
            <div className="nominal">
              <img src={moneyPouch} alt="" />
              <h4>{rupiah(sliderValue)}</h4>
              <div />
            </div>

            <Slider
              min={sliderMinVal}
              max={sliderMaxVal}
              defaultValue={sliderdefaultVal}
              step={sliderStepVal}
              value={sliderValue}
              onChange={handleSliderChange}
              className="slider"
            />
          </div>
          <div className="loan-options">
            <div className="option-list">
              {loanOption.map((e, i) => (
                <div
                  className={selectedLoan === e ? "option active" : "option"}
                  key={i}
                  onClick={() => {
                    setSelectedLoan(e)
                  }}
                >
                  {e.toString() + " " + t("home.banner.days")}
                </div>
              ))}
            </div>
          </div>
          <div className="total-paid">
            <img src={moneyPaid} alt="" />
            <div className="nominal">
              <p>{t("home.banner.total-to-be-paid")}</p>
              <h4>{rupiah(calculatePaidAmount(sliderValue, selectedLoan))}</h4>
            </div>
          </div>

          <div className="download-link">
            <a
              href={
                checkDevice() == "Android"
                  ? playStore
                  : checkDevice() == "iOS"
                  ? appStore
                  : otherDownload
              }
              target={checkDevice() == "Other" ? "_self" : "_blank"}
            >
              {t("home.banner.apply-now")}
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default LoanCalculator
