import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"
import { EffectCoverflow, Pagination } from "swiper"
import { useTranslation } from "react-i18next"
import { useMediaQuery, useTheme } from "@material-ui/core"
import "./borrowerTestimony.scss"

function BorrowerTestimony() {
  const { t } = useTranslation()

  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"))
  return (
    <div className="testimoni">
      <div className="section-title">
        <span className="section-text">{t(`home.what-they-said`)}</span>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        slidesPerView={isDesktop ? 3 : 1}
        slidesPerGroup={isDesktop ? 2 : 1}
        initialSlide={0}
        pagination={{ clickable: true }}
        coverflowEffect={{
          rotate: 0,
          stretch: -40,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper testimoni-swiper"
      >
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/borrower-Kaaris.jpg"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Kaaris Wantoro</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(e => (
                <StaticImage
                  key={e}
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.borrower-testimony-1`)}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/borrower-sonykosasih.jpg"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Sony Kosasih</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(e => (
                <StaticImage
                  key={e}
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.borrower-testimony-2`)}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/borrower-Bahreni.jpg"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Bahreni</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(e => (
                <StaticImage
                  key={e}
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.borrower-testimony-3`)}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/borrower-Yuliani.jpg"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Yuliani Fibriana Nurwijayanti</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(e => (
                <StaticImage
                  key={e}
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.borrower-testimony-4`)}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/borrower-Nanang.jpg"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Nanang Suryana</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(e => (
                <StaticImage
                  key={e}
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.borrower-testimony-5`)}</span>
          </div>
        </SwiperSlide>
      </Swiper>
      <StaticImage
        src={"../../assets/images/decoration/testimoni-circle-1.svg"}
        className="testimoni-circle-1"
        alt=""
      />
      <StaticImage
        src={"../../assets/images/decoration/testimoni-circle-2.svg"}
        className="testimoni-circle-2"
        alt=""
      />
    </div>
  )
}

export default BorrowerTestimony
