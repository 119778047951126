import React from "react"
import { ButtonBase, Typography } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/styles"

const useStyles = makeStyles(props => ({
  root: {
    background: "linear-gradient(180deg, #FFFFFF 5.95%, #FEF4EA 97.71%)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "16px",
  },
}))

const GradientText = withStyles({
  root: {
    textAlign: "center",
    fontWeight: 500,
    background: "-webkit-linear-gradient(180deg, #F89F37 0%, #F28E1E 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
})(Typography)

const BannerISOButton = props => {
  const classes = useStyles()

  return (
    <ButtonBase
      href={props.fileUrl}
      target="_blank"
      rel="noreferrer"
      focusRipple
      className={classes.root}
      style={{
        width: props.smallSize ? "38%" : "30%",
        padding: props.smallSize ? "8px 14px" : "10px 23px",
      }}
    >
      <GradientText
        variant="subtitle1"
        className={classes.text}
        style={{
          fontSize: props.smallSize ? 10 : 16,
        }}
      >
        {props.title}
      </GradientText>
      {props.img}
    </ButtonBase>
  )
}

export default BannerISOButton
